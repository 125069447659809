import type { FC, PropsWithChildren } from "react";

import { ForceTheme } from "@moment/design-system/ThemeProvider";

export const AuthContainer: FC<PropsWithChildren> = ({ children }) => {
	return (
		<ForceTheme theme="dark">
			<div className="bg-primary absolute inset-0 flex h-screen w-full text-center">
				{children}
			</div>
		</ForceTheme>
	);
};
