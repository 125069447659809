import { type FC } from "react";

/**
 * todo: make this an svg file
 */

export const MomentLogo: FC<{ className?: string }> = ({ className }) => {
	return (
		<svg
			className={className}
			width="196"
			height="48"
			viewBox="0 0 196 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.2718 36.728C4.24264 29.6986 4.24273 18.3018 11.2721 11.2725C18.3015 4.24303 31.0711 2.78719 40.9706 7.02983L36.7264 11.274C43.7555 18.3035 43.7554 29.7002 36.7261 36.7296C29.6967 43.759 16.9289 45.2131 7.02937 40.9705L11.2718 36.728ZM31.0708 16.929C27.1655 13.0241 20.8341 13.0242 16.9289 16.9293C13.0237 20.8346 13.0237 27.1662 16.9289 31.0715L16.9274 31.073C20.8327 34.978 27.1641 34.9779 31.0692 31.0727C34.9745 27.1675 34.9745 20.8358 31.0692 16.9306L31.0708 16.929Z"
				fill="currentColor"
			/>
			<path
				d="M63.096 38V12.872H67.92L74.652 25.652H74.868L81.6 12.872H86.208V38H82.32V18.776H82.14L80.16 22.844L74.652 32.888L69.144 22.844L67.164 18.776H66.984V38H63.096ZM99.4723 38.432C94.2523 38.432 90.8683 34.616 90.8683 28.64C90.8683 22.664 94.2523 18.848 99.4723 18.848C104.692 18.848 108.076 22.664 108.076 28.64C108.076 34.616 104.692 38.432 99.4723 38.432ZM99.4723 35.192C102.136 35.192 103.936 33.536 103.936 30.224V27.056C103.936 23.744 102.136 22.088 99.4723 22.088C96.8083 22.088 95.0083 23.744 95.0083 27.056V30.224C95.0083 33.536 96.8083 35.192 99.4723 35.192ZM116.373 38H112.449V19.28H116.373V22.376H116.553C117.309 20.432 118.713 18.848 121.557 18.848C124.077 18.848 126.201 20.072 127.101 22.664H127.209C127.893 20.576 129.837 18.848 132.861 18.848C136.569 18.848 138.765 21.512 138.765 26.12V38H134.841V26.588C134.841 23.708 133.761 22.232 131.385 22.232C129.405 22.232 127.569 23.276 127.569 25.436V38H123.645V26.588C123.645 23.672 122.529 22.232 120.225 22.232C118.281 22.232 116.373 23.276 116.373 25.436V38ZM151.539 38.432C146.211 38.432 142.935 34.616 142.935 28.64C142.935 22.664 146.211 18.848 151.539 18.848C157.047 18.848 159.855 22.988 159.855 28.172V29.648H147.003V30.26C147.003 33.14 148.767 35.192 151.935 35.192C154.239 35.192 155.787 34.112 156.867 32.384L159.171 34.652C157.767 36.92 155.031 38.432 151.539 38.432ZM151.539 21.908C148.839 21.908 147.003 23.924 147.003 26.804V27.056H155.715V26.696C155.715 23.816 154.131 21.908 151.539 21.908ZM168.123 38H164.199V19.28H168.123V22.376H168.303C169.095 20.36 170.679 18.848 173.559 18.848C177.375 18.848 179.643 21.512 179.643 26.12V38H175.719V26.624C175.719 23.708 174.603 22.232 172.191 22.232C170.139 22.232 168.123 23.276 168.123 25.472V38ZM193.685 38H190.265C187.565 38 186.125 36.488 186.125 33.932V22.484H183.209V19.28H184.793C186.089 19.28 186.521 18.74 186.521 17.444V14.168H190.049V19.28H193.973V22.484H190.049V34.796H193.685V38Z"
				fill="currentColor"
			/>
		</svg>
	);
};
