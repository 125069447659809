import LoginIcon from "@mui/icons-material/Login";
import cx from "classnames";
import { type NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import styles from "./login.module.css";

import { PageSpinner } from "@moment/design-system/PageSpinner";

import { MomentLogo } from "~/assets/MomentLogo";
import { useAuth } from "~/auth/useAuth";
import { AuthContainer } from "~/components/auth/AuthContainer";
import { Button } from "~/ui/Button";

/**
 * Login screen for an app.
 *
 * Use this login screen when the user is forcibly redirected to login,
 * so that they can choose whether to login or not.
 *
 * If the user is taking an action to login (e.g. clicking top bar
 * button to login) then we should not direct the user here.
 */
const Login: NextPage = () => {
	const { loginWithRedirect } = useAuth();

	const router = useRouter();
	const [showLoading, setShowLoading] = useState(true);

	useEffect(() => {
		if (router.isReady) {
			if (!router.query["code"]) {
				setShowLoading(false);
			}
			// does nothing. in both success and failure case this page is only interestitial.
		}
	}, [router.isReady, router.query]);

	return (
		<AuthContainer>
			<div className="m-auto space-y-10 bg-primary">
				<div className="flex items-center space-x-6">
					<MomentLogo className={cx(styles["fade-in-animation"])} />
				</div>
				<div className={styles["fade-in-animation"]}>
					{showLoading ? (
						<PageSpinner message="Redirecting..." />
					) : (
						<Button
							className="mx-auto"
							size="md"
							preset="primary"
							onClick={() =>
								loginWithRedirect({
									redirectPath: "/docs",
								})
							}
							startIcon={<LoginIcon />}
						>
							Login to Moment
						</Button>
					)}
				</div>
			</div>
		</AuthContainer>
	);
};

export default Login;
